import React from "react";
import App from "next/app";
import { MitraProvider } from "../core/context/MitraContext";
import { SaHiringProvider } from "../core/context/SAHiringContext";
// import Script from "next/script";
class _app extends App {
  render() {
    const { Component, pageProps } = this.props;
    return (
      <React.Fragment>
        {/* <Script
          dangerouslySetInnerHTML={{
            __html: `   
          var clevertap = {
            event: [],
            profile: [],
            account: [],
            region: "sg1",
            onUserLogin: [],
            notifications: [],
            privacy: []
          };
          clevertap.account.push({ id: "TEST-K57-645-995Z" });
          clevertap.privacy.push({ optOut: false }); //set the flag to true, if the user of the device opts out of sharing their data
          clevertap.privacy.push({ useIP: false }); //set the flag to true, if the user agrees to share their IP data
          (function () {
            var wzrk = document.createElement('script');
            wzrk.type = 'text/javascript';
            wzrk.async = true;
            wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/a.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(wzrk, s);
          })();
          clevertap.notificationCallback = function(msg){
          clevertap.raiseNotificationViewed();
          console.log(JSON.stringify(msg));
          };
        `
          }}
        /> */}
        <SaHiringProvider>
          <MitraProvider>
            <Component {...pageProps} />{" "}
          </MitraProvider>
        </SaHiringProvider>
      </React.Fragment>
    );
  }
}

export default _app;
